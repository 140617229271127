import { ChainId } from '@sdk/constants'

// ADD CHAIN
// Revalidate interval in milliseconds
export const POOLS_FAST_REVALIDATE = {
  [ChainId.ZETA]: 10_000,
  [ChainId.ZETA_TESTNET]: 10_000,
  [ChainId.ZULU_TESTNET]: 10_000,
} as const satisfies Record<ChainId, number>

// ADD CHAIN
// Revalidate interval in milliseconds
export const POOLS_NORMAL_REVALIDATE = {
  [ChainId.ZETA]: 15_000,
  [ChainId.ZETA_TESTNET]: 15_000,
  [ChainId.ZULU_TESTNET]: 15_000,
} as const satisfies Record<ChainId, number>

// ADD CHAIN
export const POOLS_SLOW_REVALIDATE = {
  [ChainId.ZETA]: 20_000,
  [ChainId.ZETA_TESTNET]: 20_000,
  [ChainId.ZULU_TESTNET]: 20_000,
} as const satisfies Record<ChainId, number>
